.grid {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(12, 1fr);
    padding: 0;
    gap: 0 3%;
    width: 100%;
    height: 100%;
}

.icon {
    grid-area: 1 / 1 / 3 / 2;
    justify-self: center;
    align-self: center;
}
.title {
    grid-area: 1 / 2 / 1 / 12;
    align-items: start;
    align-self: center;
    font-size: large;
    font-weight: 500;
    text-align: left;
}

.date {
    grid-area: 2 / 2 / 3 / 12;
    justify-self: start;
    align-self: center;
    font-weight: 300;
    text-align: left;
}
.arrow {
    grid-area: 1 / 12 / 3 / 13;
    justify-self: end;
    align-self: center;
}

.OkButton {
    background-color: white;
    color: rgba(85,136,38,0.85);
    font-size: 18px;
    box-shadow: none;
    transition: box-shadow 0.3s ease, background-color 0.3s ease;
}

.OkButton:hover {
    box-shadow: 0 0 0 0 #ffffff;
    background-color: rgba(85,136,38,0.05);
}

.MuiDialogContent-root {
    overflow-y: auto;
}

.MuiDialogContent-root::-webkit-scrollbar {
    width: 10px;
}

.MuiDialogContent-root::-webkit-scrollbar-track {
    background: rgba(241, 241, 241, 0.3); /* Background color of the track */
    border-radius: 20px; /* To make it rounded */
}

.MuiDialogContent-root::-webkit-scrollbar-thumb {
    background: #e4e4e4; /* Background color of the scrollbar itself */
    border-radius: 20px; /* To make it rounded */
    transition: background 0.3s;
}

.MuiDialogContent-root::-webkit-scrollbar-thumb:hover {
    background: #c5c5c5; /* Background color of the scrollbar on hover */
    transition: background 0.15s;
}

@media (max-width: 700px) {
    .grid {
        padding: 0;
    }
    .title {
        font-size: 16px;
    }
    .date {
        font-size: 14px;
    }
}
@media (max-width: 400px) {
    .grid {
        padding: 0;
    }
    .title {
        font-size: 15px;
    }
    .date {
        font-size: 13px;
    }
    .grid {
        padding: 0;
    }
}