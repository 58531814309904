.messagesContainer {
    background-color: rgba(85, 136, 38, 0.70);
    margin: 4svh 10% 0 10%;
    display: flex;
    height: 90svh;
    width: 70vw;
    border-radius: 20px;
    flex-direction: column;
    padding: 30px 20px 30px 30px;
    position: relative;
    overflow: hidden;
}

.messages {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
}
.messages::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
}

.messages::-webkit-scrollbar-track {
    background: rgba(241, 241, 241, 0.3); /* Background color of the track */
    border-radius: 20px; /* To make it rounded */
}

.messages::-webkit-scrollbar-thumb {
    background: #e4e4e4; /* Background color of the scrollbar itself */
    border-radius: 20px; /* To make it rounded */
    transition: background 0.3s;
}

.messages::-webkit-scrollbar-thumb:hover {
    background: #c5c5c5; /* Background color of the scrollbar on hover */
    transition: background 0.15s;
}

.contentWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-right: 10px solid transparent;
}

.content {
    margin-top: auto;
    display: flex;
    flex-direction: column;
}

.header {
    color: white;
    font-size: 24px;
    font-style: italic;
    font-weight: 500;
    margin: -3vh 0 0 0;
}

.message {
    background-color: rgb(161, 93, 52);
    max-width: 70%;
    display: inline-block;
    margin: 1px 0 1.5vh 0;
    padding: 5px 8px;
    border-radius: 10px;
    color: white;
    font-size: 18px;
    float: right;
    overflow-wrap: break-word;
}

.answer {
    background-color: rgb(133, 134, 136);
    max-width: 70%;
    display: inline-block;
    margin: 1vh 0;
    padding: 5px 8px;
    border-radius: 10px;
    color: white;
    font-size: 18px;
    float: left;
    overflow-wrap: break-word;
}

.textInput {
    color: white;
    font-size: 18px;
    width: 93%;
    margin-top: 0.5%;
    margin-left: 1.5%;
    margin-right: 5%;
    overflow-y: auto;
    -ms-overflow-style: none;  /* Hiding scrollbar for IE and Edge */
    scrollbar-width: none;  /* Hiding scrollbar for Firefox */
}
.textInput::-webkit-scrollbar {
    display: none; /* Hiding scrollbar for Chrome, Safari and Opera */
}

.date {
    font-size: 14px;
    font-weight: 450;
    color: white;
    text-align: center;
    margin: 0;
}

@media (max-width: 700px) {
    .header {
        font-size: 22px;
    }

    .answer .message {
        font-size: 16px;
    }

    .date {
        font-size: 13px;
    }
}

@media (max-width: 400px) {
    .header {
        font-size: 18px;
    }

    .answer .message {
        font-size: 12px;
    }

    .date {
        font-size: 12px;
    }
}
