@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");

@font-face {
  font-family: 'Ringbearer';
  src: url('./font/RINGM___.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
}
body {
  min-height: 100svh;
  display: flex;
  font-weight: 400;
  font-family: "Ringbearer", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span,
div,
input
{
  font-family: "Ringbearer", sans-serif;
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
  color: #ffffff;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #f1e6e6;
  margin: 0;
}

.forgot-password a {
  color: rgb(225, 255, 200);
}

