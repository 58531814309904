.auth-inner {
    min-width: 300px;
    width: 40%;
    margin: auto;
    background: rgba(84, 108, 62, 0.75);
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all 0.3s;
}

.auth-inner .form-control:focus {
    border-color: rgb(164, 225, 109);
    background: rgba(251, 250, 229, 0.75);
}

.auth-inner .btn.btn-primary {
    background: rgba(85,136,38,0.75);
    border-color: #ffff;
}
.auth-inner .btn.btn-primary:active {
    background: rgba(85, 136, 38, 0.5);
    border-color: #fff;
}
.auth-inner .btn.btn-primary:focus {
    background: rgba(85, 136, 38, 0.6);
    border-color: #fff;
}
.auth-inner .btn.btn-primary:hover {
    background: rgba(75, 120, 34, 0.85);
    border-color: #fff;
}

.auth-inner input {
    background: rgba(251, 250, 229, 0.5);
}
/* Autofill override */
.auth-inner input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px rgba(251, 250, 229, 0.75) inset !important;
    box-shadow: 0 0 0 1000px rgba(251, 250, 229, 0.75) inset !important;
    -webkit-text-fill-color: #000 !important; /* Ensures text color remains readable */
}

.auth-inner label {
    color: #ffff;
}

.test {
    color: #6baa30;
}