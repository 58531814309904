.grid {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(12, 1fr);
    gap: 0 3%;
    width: 100%;
    height: 100%;
}

.icon {
    grid-area: 1 / 1 / 3 / 2;
    justify-self: center;
    align-self: center;
}
.title {
    grid-area: 1 / 2 / 1 / 12;
    align-items: start;
    align-self: center;
    font-size: large;
    font-weight: 500;
    text-align: left;
}

.desc {
    grid-area: 2 / 2 / 3 / 12;
    justify-self: start;
    align-self: center;
    font-weight: 300;
    text-align: left;
}
.arrow {
    grid-area: 1 / 12 / 3 / 13;
    justify-self: end;
    align-self: center;
}
.Header {
    text-align: center;
    padding: 5px;
    margin: 4svh 10% 2svh 10%;
    background-color: rgba(165, 86, 45, 0.9);
    border: solid 1px #454849;
    border-radius: 10px;
    font-weight: 500;
    font-size: 18px;
    color: white;
    font-style: italic;
}

.OkButton {
    background-color: white;
    color: rgba(85,136,38,0.85);
    font-size: 18px;
    box-shadow: none;
    transition: box-shadow 0.3s ease, background-color 0.3s ease;
}

.OkButton:hover {
    box-shadow: 0 0 0 0 #ffffff;
    background-color: rgba(85,136,38,0.05);
}

.MuiDialog-paper {
    overflow-y: auto;
    scrollbar-width: none;
}

.scrollbar-style-1::-webkit-scrollbar {
    width: 10px;
}
.scrollbar-style-1::-webkit-scrollbar-track {
    background: rgba(100, 100, 100, 0);
    border-radius: 20px;
}
.scrollbar-style-1::-webkit-scrollbar-thumb {
    background: #3e3e3e;
    border-radius: 20px;
    transition: background 0.3s;
}
.scrollbar-style-1::-webkit-scrollbar-thumb:hover {
    background: #474747;
    transition: background 0.15s;
}

.scrollbar-style-2::-webkit-scrollbar {
    width: 10px;
}
.scrollbar-style-2::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 20px;
}
.scrollbar-style-2::-webkit-scrollbar-thumb {
    background: #e4e4e4;
    border-radius: 20px;
    transition: background 0.3s;
}
.scrollbar-style-2::-webkit-scrollbar-thumb:hover {
    background: #bcbcbc;
    transition: background 0.15s;
}


@media(max-width: 700px) {
    .Header {
        font-size: 16px; /* for medium screens */
    }
    .title {
        font-size: 16px;
    }
    .desc {
        font-size: 13px;
    }
}
@media (max-width: 400px) {
    .Header {
        font-size: 13px; /* for small screens */
    }
    .title {
        font-size: 13px;
    }
    .desc {
        font-size: 11px;
    }
}
